// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  client: 'climatecare-travel',
  production: false,
  stripePublishableKey: 'pk_test_AKFzXNrTAU4SAL1NTUimhbl3',
  stripePublishableKeyTest : '%stripeTestClientId%',
  paypalClientId: 'ASWolWWmcWqVsTLFo4wdwlURxKGrXJYsWlWXoPzNzkza6apKHo_MkCb65jKoUpWornyGqqGUZTog2g7A',
  paypalClientIdTest: '%paypalTestClientId%',
  siteUrl: 'https://dev-climatecare-travel.co2analytics.com/',
  defaultLanguage: 'en',
  minimumBaseCurrencyAmount: 0.75,
  txOrigins: [
    '*'
  ],
  rxOrigins: [
    '*'
  ],
  partnerConfig: {
    partnerKey: 'b975d6a3-b915-4b4b-893a-ff2978eef3e5',
    domain: 'climatecare.org'
  },
  apis: {
    domain: 'https://dev-srvc-calculators.co2analytics.com',
    fx: 'https://dev-srvc-calculators.co2analytics.com',
    fin: 'https://dev-srvc-calculators.co2analytics.com',
    version: 'v1.0.0'
  },
  bypassCode: '%bypassCode%',
  componentHierarchy: {
    headerComponents: [
      { templateName: 'calculatorHeaderComponent', componentName: 'CalculatorHeaderComponent' }
    ],
    featureComponents: [
      {
        name: 'intro',
        seqn: '0',
        title: 'Please select a category to begin calculating your carbon emissions',
        theme: {
          fillColor: '#FFFFFF',
          textClass: 'calculator-selector-text-dark'
        },
        component: { templateName: 'introComponent', componentName: 'IntroComponent' }
      },
      {
        name: 'flights',
        seqn: '1',
        title: 'Flights',
        theme: {
          fillColor: '#21536F',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'flightsComponent', componentName: 'ClimateCareFlightsComponent' }
      },
      {
        name: 'accommodations',
        seqn: '2',
        title: 'Accommodations',
        theme: {
          fillColor: '#306E8F',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'accommodationsComponent', componentName: 'ClimateCareAccommodationsComponent' }
      },
      {
        name: 'transportation',
        seqn: '3',
        title: 'Road Transport',
        theme: {
          fillColor: '#4082A6',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'transportationComponent', componentName: 'ClimateCareTransportationComponent' }
      },
      {
        name: 'consumption',
        seqn: '4',
        title: 'Food',
        theme: {
          fillColor: '#4F9BC5',
          textClass: 'calculator-selector-text-dark'
        },
        component: { templateName: 'consumptionComponent', componentName: 'ClimateCareConsumptionComponent' }
      }
    ],
    global: [
      {
        component: { defaultFeatureIndex: 0 }
      },
      {
        component: { templateName: 'offsetProjectsComponent', componentName: 'OffsetProjectsComponent' }
      },
      {
        component: { templateName: 'cartComponent', componentName: 'CartComponent' }
      },
      {
        component: { templateName: 'customerDetailsComponent', componentName: 'CustomerDetailsComponent' }
      },
      {
        component: { templateName: 'payOffsetsComponent', componentName: 'PayOffsetsComponent' }
      },
      {
        component: { templateName: 'confirmationComponent', componentName: 'ConfirmationComponent' }
      }
    ],
    footerComponents: [
      { templateName: 'icroaFooterComponent', componentName: 'IcroaFooterComponent' }
    ]
  }
}

// For easier debugging in development mode, you can import the following file
// to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//
// This import should be commented out in production mode because it will have a negative impact
// on performance if an error is thrown.
//
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
